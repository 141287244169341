import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Saldo.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MeusPagamentos = () => {
  const [detalhamento, setDetalhamento] = useState([]);
  const [saldoTotal, setSaldoTotal] = useState(0);
  const [saldoPendente, setSaldoPendente] = useState(0);
  const [saldoLiberado, setSaldoLiberado] = useState(0);
  const [totalSaque, setTotalSaque] = useState(0);
  const [historicoSaques, setHistoricoSaques] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tipoChavePIX, setTipoChavePIX] = useState('');
  const [chavePIX, setChavePIX] = useState('');

  useEffect(() => {
    fetchPagamentos();
  }, []);

  const fetchPagamentos = async () => {
    try {
      const response = await axios.get('https://api.topiainvest.com.br/api/meus-projetos/saldo-detalhado', { withCredentials: true });
      const { saldoTotal, saldoPendente, saldoLiberado, detalhamentoProjetos, totalSaque, historicoSaques } = response.data;
      
      setSaldoTotal(saldoTotal);
      setSaldoPendente(saldoPendente);
      setSaldoLiberado(saldoLiberado);
      setDetalhamento(detalhamentoProjetos);
      setTotalSaque(totalSaque);
      setHistoricoSaques(historicoSaques);
      setIsLoading(false);
    } catch (error) {
      toast.error('Erro ao buscar os pagamentos.');
      setIsLoading(false);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const solicitarSaque = async () => {
    if (!tipoChavePIX || !chavePIX) {
      toast.error('Por favor, preencha todos os campos.');
      return;
    }

    try {
      await axios.post(
        'https://api.topiainvest.com.br/api/solicitar-saque',
        { TIPOCHAVEPIX: tipoChavePIX, CHAVEPIX: chavePIX },
        { withCredentials: true }
      );
      toast.success('Saque solicitado com sucesso!');
      closeModal();
      fetchPagamentos();
    } catch (error) {
      toast.error('Erro ao solicitar saque. Tente novamente.');
    }
  };

  return (
    <div className="meus-pagamentos-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />

      <h2 className="titulo-pagina">Meus Pagamentos</h2>

      <div className="saldos">
        <div className="saldo-card saldo-total">
          <h3>Saldo Total: R$ {saldoTotal.toFixed(2)}</h3>
        </div>
        <div className="saldo-card saldo-liberado">
          <h3>Saldo Liberado: R$ {saldoLiberado.toFixed(2)}</h3>
        </div>
        <div className="saldo-card saldo-pendente">
          <h3>Saldo Pendente: R$ {saldoPendente.toFixed(2)}</h3>
        </div>
        <div className="saldo-card saldo-saques">
          <h3>Saques Realizados: R$ {totalSaque.toFixed(2)}</h3>
        </div>
      </div>

      <button onClick={openModal} className="solicitar-saque-button">
        Solicitar Saque
      </button>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-saque">
            <h3>Solicitar Saque</h3>
            <p>Você está pedindo o saque do valor liberado: <strong>R$ {saldoLiberado.toFixed(2)}</strong></p>

            <label>Tipo de Chave PIX:</label>
            <select value={tipoChavePIX} onChange={(e) => setTipoChavePIX(e.target.value)}>
              <option value="">Selecione o tipo de chave<br/></option>
              <option value="email">E-mail</option>
              <option value="telefone">Telefone</option>
              <option value="cpf">CPF</option>
              <option value="aleatoria">Chave Aleatória</option>
            </select>
            <br/>
            <label><br/>Chave PIX:</label>
            <input
              type="text"
              value={chavePIX}
              onChange={(e) => setChavePIX(e.target.value)}
              placeholder="Digite sua chave PIX"
            />

            <div className="modal-actions">
              <button onClick={solicitarSaque} className="modal-button">Confirmar Saque</button>
              <button onClick={closeModal} className="modal-button cancel">Cancelar</button>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="loading">Carregando...</div>
      ) : (
        <div>
          {historicoSaques.length > 0 && (
            <div className="historico-saques">
              <h3>Histórico de Saques</h3>
              {historicoSaques.map((saque, index) => (
                <div key={index} className="historico-item card">
                  <p><strong>Valor:</strong> R$ {parseFloat(saque.valor).toFixed(2)}</p>
                  <p><strong>Chave PIX:</strong> {saque.descricao}</p>
                  <p><strong>Status:</strong> {saque.status}</p>
                  <p><strong>Data:</strong> {new Date(saque.data).toLocaleDateString()}</p>
                </div>
              ))}
            </div>
          )}

          <div className="historico-detalhado">
            <h3>Histórico de Arrecadação - Projetos</h3>
            {detalhamento.map((projeto) => (
              <div key={projeto.projeto.id} className="historico-projeto card">
                <h3>{projeto.projeto.nome}</h3>
                <p><strong>Valor Arrecadado:</strong> R$ {parseFloat(projeto.projeto.arrecadado).toFixed(2)}</p>

                {Array.isArray(projeto.financiadores) && projeto.financiadores.length > 0 && (
                  <>
                    <h4>Financiadores:</h4>
                    {projeto.financiadores.map((financiador, index) => (
                      <div key={index} className="historico-item">
                        <p><strong>Nome:</strong> {financiador.nome} {financiador.sobrenome}</p>
                        <p><strong>Email:</strong> {financiador.email}</p>
                        <p className={financiador.saque ? "valor-saque" : "valor-entrada"}>
                          <strong>Valor:</strong> R$ {parseFloat(financiador.valor).toFixed(2)}
                        </p>
                        <p className={financiador.saque ? "valor-saque" : "valor-entrada"}>
                          <strong>{financiador.saque ? 'Saque' : 'Entrada'}</strong>
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
 
export default MeusPagamentos; 