import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaShareAlt,  FaWhatsapp, FaInstagram, FaFacebook, FaYoutube, FaLinkedin, FaLink } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { BeatLoader } from 'react-spinners';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = ({ images }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    window.dispatchEvent(new Event('resize'));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (!mounted) {
    return null; 
  }

  return (
    <Slider {...settings} className="custom-carousel">
      {images.map((img, index) => (
        <div key={index} className="carousel-slide">
          <img src={img} alt={`Projeto Imagem ${index + 1}`} className="carousel-image" />
        </div>
      ))}
    </Slider>
  );
};

const Modal = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 20px;
  }

  .share-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      font-size: 18px;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
`;
const CarouselContainer = styled.div`
  flex: 1;

  .custom-carousel {
    max-width: 40vh; 
    overflow: hidden;
    border-radius: 10px;

    .slick-list {
      max-width: 100%;
      max-height: 80vh;  
      margin: 0 auto;    
      overflow: hidden;
    }

    .slick-slide {
      min-width: 300px;      
    }

    .carousel-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .slick-dots {
      bottom: -30px;
    }

    .slick-prev, .slick-next {
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }

    .slick-prev {
      left: 5%;
    }

    .slick-next {
      right: 5%;
    }
  }

  @media (min-width: 800px) {
    .custom-carousel {
      max-width: 60vh; 
      border-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    .slick-list {
      max-width: 100%;
      max-height: 30vh;  
      margin: 0 auto;    
      overflow: hidden;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column; /* Muda a direção para coluna */

  @media (min-width: 768px) {
    flex-direction: row; 
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-height: 40vh; /* Limita a altura da imagem */
  margin-bottom: 20px; /* Espaço abaixo da imagem no layout de coluna */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 40vh;
    max-height: 40vh;
    border-radius: 10px;
    object-fit: contain;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
`;

const Title = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 10px;
`;

const SubInfo = styled.p`
  font-size: 1rem;
  color: #7d7d7d;
`;

const OdsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  img {
    width: 15vh;
    height: auto;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    img {
      width: 80px;
    }
  }

  @media (max-width: 480px) {
    img {
      width: 12vh;
    }
  }
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Progress = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 10px;
  height: 25px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;

  .progress-bar {
    background-color: #4caf50;
    height: 100%;
    width: ${(props) => props.width || 0}%;
  }

  .progress-label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    color: #001326;
  }
`;

const Amount = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
`;

const DaysRemaining = styled.p`
  font-size: 1rem;
  color: ${(props) => (props.expired ? 'red' : '#333')};  // Exibe em vermelho se expirado
  margin-top: 10px;
`;
const Button = styled.button`
  background-color: ${(props) => (props.disabled ? '#cccccc' : '#4caf50')};
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 20px;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#cccccc' : '#45a049')};
  }
`;



const DescriptionContainer = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%; /* Limita a largura máxima ao tamanho do container */
  overflow-wrap: break-word; /* Garante que o conteúdo se ajuste à largura disponível */

  .descricao-projeto {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    max-width: 100%; /* Limita a largura do conteúdo HTML */
    word-wrap: break-word; /* Quebra as palavras longas */
    
    img, iframe {
      max-width: 100%; /* Garante que as imagens e vídeos não ultrapassem a largura do container */
      display: block;
      margin: 0 auto; /* Centraliza imagens e vídeos */
    }
  }
`;
const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 15px;
  margin-bottom: 20px;
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f0f0f0;
    text-decoration: none;
    color: inherit;
    font-size: 24px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }
  }
`;
const renderSocialIcons = (projeto) => {
  const socialLinks = [
    { id: 'tb03_whatsapp', url: projeto.tb03_whatsapp, icon: <FaWhatsapp color="#25D366" /> },
    { id: 'tb03_instagram', url: projeto.tb03_instagram, icon: <FaInstagram color="#E4405F" /> },
    { id: 'tb03_facebook', url: projeto.tb03_facebook, icon: <FaFacebook color="#4267B2" /> },
    { id: 'tb03_youtube', url: projeto.tb03_youtube, icon: <FaYoutube color="#FF0000" /> },
    { id: 'tb03_linkedin', url: projeto.tb03_linkedin, icon: <FaLinkedin color="#0077B5" /> },
    { id: 'tb03_x', url: projeto.tb03_x, icon: <FaXTwitter color="#1DA1F2" /> },
  ];

  return socialLinks
    .filter(link => link.url) 
    .map(link => (
      <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer">
        {link.icon}
      </a>
    ));
};

const handleShare = async (projectName, openModal) => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: projectName,
        text: `Confira o projeto ${projectName} no Topia Invest!`,
        url: window.location.href,
      });
      toast.success('Projeto compartilhado com sucesso!');
    } catch (error) {
      toast.error('Erro ao compartilhar o projeto.');
    }
  } else {
    openModal(); 
  }
};


const handleCopyLink = async () => {
  try {
    await navigator.clipboard.writeText(window.location.href);
    toast.info('Link copiado para a área de transferência!');
  } catch (err) {
    toast.error('Erro ao copiar o link.');
  }
};


const ShareModal = ({ isOpen, closeModal, projectName }) => {
  const shareUrl = window.location.href;
  const shareText = `Confira o projeto ${projectName} no Topia Invest!`;

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <span className="close" onClick={closeModal}>&times;</span>
        <h2>Compartilhar Projeto</h2>
        <div className="share-buttons">
          <button onClick={handleCopyLink}>
            <FaLink size={24} color="#001326" />
            <span style={{ marginLeft: '10px' }}>Copiar Link</span>
          </button>

          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={24} color="#4267B2" />
            <span style={{ marginLeft: '10px' }}>Facebook</span>
          </a>

          <a
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter size={24} color="#1DA1F2" />
            <span style={{ marginLeft: '10px' }}>Twitter</span>
          </a>

          <a
            href={`https://wa.me/?text=${encodeURIComponent(shareText + ' ' + shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={24} color="#25D366" />
            <span style={{ marginLeft: '10px' }}>WhatsApp</span>
          </a>
        </div>
      </ModalContent>
    </Modal>
  );
};
const DetalheProjeto = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projeto, setProjeto] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => setIsModalOpen(true);
const closeModal = () => setIsModalOpen(false);
  const hasLoggedView = useRef(false);

  useEffect(() => {
    let isMounted = true;
    
    const fetchProjeto = async () => {
      try {
        const response = await axios.get(`https://api.topiainvest.com.br/api/ver_projetos/id/${id}`);
        if (isMounted) {
          setProjeto(response.data);
        }
      } catch (error) {
        toast.error('Erro ao carregar o projeto.');
      }
    };

    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/categorias');
        if (isMounted) {
          setCategorias(response.data);
        }
      } catch (error) {
        toast.error('Erro ao carregar categorias.');
      }
    };

    const registrarVisualizacao = async () => {
      if (hasLoggedView.current) return; 
      hasLoggedView.current = true; 

      try {
        const payload = {
          path: window.location.pathname,
          project_id: id,
          user_agent: navigator.userAgent,
          referer: document.referrer,
          tb11_access_time: new Date().toISOString(),
          ip_address: '', 
          device: /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
          os: navigator.platform,
          resolution: `${window.screen.width}x${window.screen.height}`,
          session_duration: '',
          user_id: null 
        };

        await axios.post('https://api.topiainvest.com.br/api/log-access', payload);
      } catch (error) {
        console.error('Erro ao registrar visualização:', error);
      }
    };

    fetchProjeto();
    fetchCategorias();
    registrarVisualizacao(); 

    return () => {
      isMounted = false;
    };
  }, [id]);

  const calcularTempoRestante = (dataExpiracao) => {
    const hoje = new Date();
    const dataExpiracaoDate = new Date(dataExpiracao);
    const diffTime = dataExpiracaoDate - hoje;
    const diasRestantes = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  

    if (diasRestantes <= 0) {
      return -1; 
    }
  
    if (diasRestantes > 365) {
      const anos = Math.floor(diasRestantes / 365);
      const meses = Math.floor((diasRestantes % 365) / 30);
      return `${anos} ano(s) e ${meses} mês(es)`;
    } else if (diasRestantes > 30) {
      const meses = Math.floor(diasRestantes / 30);
      return `${meses} mês(es)`;
    } else {
      return `${diasRestantes} dia(s)`;
    }
  };

  const ProgressWithLabel = ({ width }) => (
    <Progress width={width}>
      <div className="progress-bar" />
      <div className="progress-label">{width.toFixed(2)}%</div>
    </Progress>
  );
  

  if (!projeto) {
    return (
      <Container>
        <BeatLoader color="#001326" />
      </Container>
    );
  }
  const imagens = [projeto.tb03_imagem];
  if (projeto.tb03_imagem_2) imagens.push(projeto.tb03_imagem_2);
  if (projeto.tb03_imagem_3) imagens.push(projeto.tb03_imagem_3);

  const diasRestantes = calcularTempoRestante(projeto.tb03_data_objetivo);
  const isExpired = diasRestantes === -1;
  const categoria = categorias.find(cat => cat.tb04_id_categoria === projeto.tb03_id_categoria);
  return (
    <Container>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar theme="light" />
      
      <Header>
      <CarouselContainer>
    {imagens.length > 1 ? (
      <ImageCarousel images={imagens} />
    ) : (
      <ImageContainer>
        <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} />
      </ImageContainer>
    )}
  </CarouselContainer>
        
        <InfoContainer>
          <Title>{projeto.tb03_nome}</Title>
          <SocialIconsContainer>
  {renderSocialIcons(projeto)}
</SocialIconsContainer>
          <SubInfo>{categoria ? categoria.tb04_descricao : 'Categoria não encontrada'}</SubInfo>
          
          <OdsContainer>
  {projeto.tb03_id_ods.split(',').map((odsId, index) => (
    <img key={index} src={`/img/ods${odsId}.png`} alt={`ODS ${odsId}`} className="ods-icon" />
  ))}
</OdsContainer>
          
<MetaContainer>
  <ProgressWithLabel width={(projeto.tb03_arrecadado / projeto.tb03_meta_bruta) * 100}
                     label={`${((projeto.tb03_arrecadado / projeto.tb03_meta_bruta) * 100).toFixed(2)}%`}>
  </ProgressWithLabel>
  <Amount>R$ {projeto.tb03_arrecadado} arrecadados</Amount>
  <p style={{ color: 'gray', fontSize: '0.8rem' }}>Meta: R$ {projeto.tb03_meta_liquida}</p>
  <DaysRemaining expired={isExpired}>
            {isExpired ? 'Projeto Expirado' : `Faltam ${calcularTempoRestante(projeto.tb03_data_objetivo)}`}
          </DaysRemaining>
  <Button onClick={() => navigate(`/financiar/${id}`)} disabled={isExpired}>
            {isExpired ? 'Projeto Expirado' : 'Financiar este projeto'}
          </Button>

            <button onClick={() => handleShare(projeto.tb03_nome, openModal)} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '20px' }}>
              <FaShareAlt size={24} color="#001326" />
              <span style={{ marginLeft: '10px' }}>Compartilhar</span>
            </button>

</MetaContainer>
        </InfoContainer>
      </Header>
      <ShareModal isOpen={isModalOpen} closeModal={closeModal} projectName={projeto.tb03_nome} />     
      <DescriptionContainer>
        <div className="descricao-projeto" dangerouslySetInnerHTML={{ __html: projeto.tb03_descricao }} />
      </DescriptionContainer>
    </Container>
  );
};

export default DetalheProjeto;
