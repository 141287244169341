import React from 'react';

const Politica = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '70%',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f5f5f5',
      lineHeight: '1.8',
      textAlign: 'justify'
    }}>
      <h1 style={{ color: '#333', fontSize: '24px', borderBottom: '1px solid #bbb', paddingBottom: '10px' }}>Política de Privacidade - Topia Invest</h1>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>1. Introdução</h2>
      <p>A sua privacidade é importante para nós. Esta Política de Privacidade descreve como a Topia Invest coleta, usa, armazena e protege suas informações pessoais. Ao utilizar nossa plataforma, você concorda com os termos desta política.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>2. Informações Coletadas</h2>
      <ul style={{ textAlign: 'left' }}>
        <li>Informações Pessoais: Nome, CPF, e-mail, data de nascimento e informações de contato são coletadas durante o processo de cadastro.</li>
        <li>Informações de Navegação: Dados de navegação, como endereço IP, tipo de navegador, páginas visitadas e tempo de permanência, são coletados automaticamente.</li>
        <li>Informações de Transações: Detalhes sobre as contribuições e financiamentos realizados na plataforma.</li>
      </ul>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>3. Uso das Informações</h2>
      <p>As informações coletadas são utilizadas para operar a plataforma, gerenciar contas, processar transações, enviar notificações e melhorar o serviço. Também são usadas para cumprir requisitos legais.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>4. Compartilhamento de Informações</h2>
      <p>Compartilhamos informações com parceiros de serviço e autoridades legais quando necessário. Informações limitadas podem ser visíveis para outros usuários em certas interações na plataforma.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>5. Segurança das Informações</h2>
      <p>Adotamos medidas de segurança para proteger suas informações, armazenamos dados em servidores seguros e realizamos backups regulares.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>6. Direitos dos Usuários</h2>
      <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Também pode optar por não receber comunicações promocionais.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>7. Cookies e Tecnologias Semelhantes</h2>
      <p>Utilizamos cookies para melhorar a experiência do usuário. Você pode controlar o uso de cookies através das configurações do seu navegador.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>8. Alterações na Política de Privacidade</h2>
      <p>As alterações na política serão notificadas através do site ou por e-mail. A data de vigência será indicada no topo do documento.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>9. Contato</h2>
      <p>Para dúvidas ou preocupações, entre em contato conosco através do nosso <a href="https://topiainvest.com.br/contato" style={{ color: '#001326' }}>formulário de contato</a>.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>Observações</h2>
      <ul style={{ textAlign: 'left' }}>
        <li>Coletar essas informações é essencial para operar a plataforma, personalizar a experiência do usuário e garantir a segurança das transações.</li>
        <li>Explicar como as informações são utilizadas ajuda a garantir transparência e construir a confiança do usuário.</li>
        <li>Definir claramente com quem as informações são compartilhadas e sob quais circunstâncias protege a privacidade do usuário e assegura conformidade legal.</li>
        <li>Garantir a segurança das informações protege os dados dos usuários e a integridade da plataforma.</li>
        <li>Proteger os direitos dos usuários é fundamental para garantir transparência e conformidade com regulamentos de proteção de dados.</li>
        <li>Informar os usuários sobre o uso de cookies e como controlá-los promove transparência e controle sobre a privacidade.</li>
        <li>Permitir atualizações regulares da política de privacidade garante que a plataforma permaneça conforme com as leis e melhores práticas em constante evolução.</li>
        <li>Oferecer um canal claro de comunicação para questões de privacidade é essencial para manter a confiança e fornecer suporte adequado aos usuários.</li>
      </ul>
    </div>
  );
};
 
export default Politica;
