import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styled from 'styled-components';
import { FaUser, FaEnvelope, FaTag, FaCommentDots } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContainer = styled.div`

  max-width: 1000px; 
  margin: 50px auto;
  padding: 25px;
  background-color: #f7f7f5;
  border-radius: 15px;
  box-shadow: 0px 6px 15px #808080; /*FOTO 1 TA SEM ESSA LINHA*/
  color: #001326;
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 25px;
  font-size: 26px;
  letter-spacing: 1.5px;
  color: #001326;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 16px;
  color: #001326;
  gap: 8px;
`;

const Input = styled.input`
  border: 2px solid #ffc85c;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  margin-top: 5px;
  &:focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #ffc85c;
  margin-top: 5px;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  background-color: #ffc85c;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #001326;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #e0b34c;
    transform: translateY(-2px);
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`;


const ContactForm = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://api.topiainvest.com.br/api/contato', data);
      if (response.status === 200) {
        toast.success('Mensagem enviada com sucesso!');
        reset();
      } else {
        toast.error('Erro ao enviar mensagem. Tente novamente.');
      }
    } catch (error) {
      toast.error('Erro ao enviar mensagem. Tente novamente.');
    }
  };

  return (
    <FormContainer>
      <FormTitle>Contato</FormTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label><FaUser /> Nome:</Label>
          <Input
            type="text"
            {...register('nome', { required: 'Nome é obrigatório' })}
          />
          {errors.nome && <ErrorMessage>{errors.nome.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label><FaEnvelope /> Email:</Label>
          <Input
            type="email"
            {...register('email', {
              required: 'Email é obrigatório',
              pattern: {
                value: /^[^@]+@[^@]+\.[^@]+$/,
                message: 'Email inválido'
              }
            })}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label><FaTag /> Assunto:</Label>
          <Input
            type="text"
            {...register('assunto', { required: 'Assunto é obrigatório' })}
          />
          {errors.assunto && <ErrorMessage>{errors.assunto.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label><FaCommentDots /> Mensagem:</Label>
          <Textarea
            rows="4"
            {...register('mensagem', { required: 'Mensagem é obrigatória' })}
          />
          {errors.mensagem && <ErrorMessage>{errors.mensagem.message}</ErrorMessage>}
        </FormGroup>
        <SubmitButton type="submit">Enviar</SubmitButton>
      </Form>
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </FormContainer>
  );
};
 
export default ContactForm;
