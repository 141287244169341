import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MeusProjetos.css';
import { useSpring, animated } from 'react-spring';

const MeusProjetos = () => {
  const [projetos, setProjetos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjetos = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/projetos', { withCredentials: true });
        setProjetos(response.data);
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
        toast.error('Erro ao buscar projetos. Tente novamente.');
      }
    };

    fetchProjetos();
  }, []);

  const statusText = {
    1: 'Aguardando Aprovação',
    2: 'Aprovado',
    3: 'Rejeitado',
    4: 'Concluído'
  };

  const statusColor = {
    1: '#ffc85c',
    2: '#28a745',
    3: '#dc3545',
    4: '#17a2b8'
  };

  const handleRowClick = (id) => {
    navigate(`/projeto/${id}`);
  };

  return (
    <div className="meus-projetos-container">
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <h2>Meus Projetos</h2>
      <table className="projetos-table">
        <thead>
          <tr>
            <th>Imagem</th>
            <th>Nome</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {projetos.map(projeto => (
            <animated.tr
              key={projeto.tb03_id_projeto}
              onClick={() => handleRowClick(projeto.tb03_id_projeto)}
              className="projeto-row"
            >
              <td>
                <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} className="projeto-imagem" />
              </td>
              <td>{projeto.tb03_nome}</td>
              <td style={{ color: statusColor[projeto.tb03_id_status] }}>
                {statusText[projeto.tb03_id_status]}
              </td>
            </animated.tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
 
export default MeusProjetos;
