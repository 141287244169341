import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNavbar from './components/Navbar';
import CarouselComponent from './components/CarouselComponent';
import Cadastro from './components/Cadastro';
import Conta from './components/Conta';
import axios from 'axios';
import CriarProjeto from './components/Criarprojeto';
import MeusProjetos from './components/Meusprojetos';
import 'react-toastify/dist/ReactToastify.css';
import DetalhesProjeto from './components/DetalhesProjeto';
import Paineladmin from './components/Paineladmin';
import DetalhesAdmin from './components/DetalhesAdmin';
import Projetos from './components/Projetos';
import DetalheProjeto from './components/ProjetoDetalhado';
import FloatingButton from './components/FloatingButton';
import Participacoes from './components/Participacoes';
import Chat from './components/Chat';
import FinaniarProjeto from './components/Financiarprojeto';
import MeusPagamentos from './components/MeusPagamentos';
import PaymentForm from './components/Cartao';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import About from './components/Sobre';
import Contato from './components/Contato';
import Footer from './components/Footer';
import Politica from './components/Politica';
import Termos from './components/Termos';
import Blog from './components/Blog';
import BlogDetail from './components/BlogEspecified';
import ODS from './components/ODS';
import ResetPassword from './components/ResetPassword';
import Saldo from './components/Saldo';
import CartaoSemente from './components/CartaoSemente';
import Faq from './components/Faq';
import ChatIA from './components/ChatIA';
 
axios.defaults.withCredentials = true;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    inject();
    injectSpeedInsights();
    const fetchUser = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/user');
        setUser(response.data);
      } catch (error) {
        console.error('Erro ao buscar informações do usuário:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return (
    <Router>
      <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1' }}>
          <TopNavbar user={user} loading={loading} />
          <Routes>
            <Route path="/login" element={<Cadastro />} />
            <Route path="/" element={<CarouselComponent />} />
            <Route path="/projetos/" element={<Projetos />} />
            <Route path="/conta/" element={<Conta user={user} />} />
            <Route path="/criar-projeto/" element={<CriarProjeto user={user}/>} />
            <Route path="/politica-de-privacidade/" element={<Politica />} />  
            <Route path="/termos-de-uso/" element={<Termos />} />                       
            <Route path="/meus-projetos/" element={<MeusProjetos />} />
            <Route path="/painel-admin/" element={<Paineladmin />} />
            <Route path="/pagamentos/" element={<MeusPagamentos />} />
            <Route path="/participacoes/" element={<Participacoes />} />
            <Route path="/participacoes/:id" element={<Chat />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/contato/" element={<Contato />} />
            <Route path="/cartao/" element={<PaymentForm />} />
            <Route path="/sobre/" element={<About />} />
            <Route path="/saldo/" element={<Saldo />} />
            <Route path="/projeto/:id" element={<DetalhesProjeto />} />
            <Route path="/projeto/admin/:id" element={<DetalhesAdmin />} />
            <Route path="/financiar/:id" element={<FinaniarProjeto user={user} />} />
            <Route path="/projetos/:id" element={<DetalheProjeto user={user} />} />
            <Route path="/blog/" element={<Blog />} />
            <Route path="/ods/" element={<ODS />} />  
            <Route path="/cartao-semente/" element={<CartaoSemente />} />           
            <Route path="/reset-password/" element={<ResetPassword />} />   
            <Route path="/faq/" element={<Faq />} />      
            <Route path="/chat/" element={<ChatIA />} />            
          </Routes>
        </div>
        <ChatIA /> 
        <Footer />
      </div>
    </Router>
  );
}

export default App;
