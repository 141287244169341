import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import './Projetos.css';
import { FaFilter, FaCalendarAlt, FaHourglassHalf } from 'react-icons/fa';

const Projetos = () => {
  const [projetos, setProjetos] = useState([]);
  const [categoriaFiltro, setCategoriaFiltro] = useState('');
  const [odsFiltro, setOdsFiltro] = useState('');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [ods, setOds] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/categorias');
        setCategorias(response.data);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    const fetchOds = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/ods');
        setOds(response.data);
      } catch (error) {
        console.error('Erro ao buscar ODS:', error);
      }
    };

    const fetchProjetos = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/ver_projetos');
        const projetosAtualizados = response.data.map(projeto => ({
          ...projeto,
          tb03_data_objetivo: new Date(new Date(projeto.tb03_data_objetivo).setDate(new Date(projeto.tb03_data_objetivo).getDate() + 1))
        }));
        setProjetos(projetosAtualizados);
        setCarregando(false);
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
        setCarregando(false);
      }
    };

    fetchCategorias();
    fetchOds();
    fetchProjetos();
  }, []);

  
  const calcularDiasRestantes = (dataExpiracao) => {
    const hoje = new Date();
    const dataExpiracaoDate = new Date(dataExpiracao);
    const diffTime = dataExpiracaoDate - hoje;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  };

  const renderExpiracao = (dataExpiracao) => {
    const diasRestantes = calcularDiasRestantes(dataExpiracao);
    if (diasRestantes < 0) {
      return (
        <div style={{ color: 'red', fontWeight: 'bold' }}>Expirado</div>
      );
    }
    return (
      <>
        <div>
          <FaCalendarAlt style={{ marginRight: '8px' }} />
          Até: {new Date(dataExpiracao).toLocaleDateString()} 
        </div>
        <div>
          <FaHourglassHalf style={{ marginRight: '8px' }} />
          {diasRestantes} dia(s) restantes
        </div>
      </>
    );
  };

  const calcularPorcentagem = (arrecadado, meta) => (arrecadado / meta) * 100;
  const corProgresso = (porcentagem) => {
    if (porcentagem < 25) return '#dc3545';
    if (porcentagem < 50) return '#ffc107';
    return '#28a745';
  };

  const handleCardClick = (id) => {
    navigate(`/projetos/${id}`);
  };

  const toggleFiltro = () => {
    setMostrarFiltros(!mostrarFiltros);
  };

  const handleFiltroChange = (setter) => (e) => {
    setter(e.target.value);
    setMostrarFiltros(false);
  };

  const filtrarProjetos = () => {
    const projetosFiltrados = projetos.filter(projeto => {
      const idsODS = projeto.tb03_id_ods.split(',').map(id => id.trim());
      const categoriaMatches = categoriaFiltro === '' || projeto.tb03_id_categoria.toString() === categoriaFiltro;
      const odsMatches = odsFiltro === '' || idsODS.includes(odsFiltro);
      return categoriaMatches && odsMatches;
    });

    if (projetosFiltrados.length === 0 && !carregando) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontSize: '24px' }}>
          Sem projetos
        </div>
      );
    }

    return projetosFiltrados.map(projeto => (
      <div key={projeto.tb03_id_projeto} className="projeto-card" onClick={() => handleCardClick(projeto.tb03_id_projeto)}>
        <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} className="projeto-imagem" style={{ width: '300px', height: '300px' }} />
        <div className="projeto-info">
          <h3 className="projeto-titulo">{projeto.tb03_nome}</h3>
          <div className="projeto-expiracao">
            {renderExpiracao(projeto.tb03_data_objetivo)}
          </div>
          <div className="progresso-barra-container">
            <div className="progresso-barra" style={{
              width: `${calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta)}%`,
              backgroundColor: corProgresso(calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta))
            }}></div>
            <span className="progresso-info">
              {calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta).toFixed(2)}% de R$ {projeto.tb03_meta_bruta}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="projetos-container">
      {carregando ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <BeatLoader color="#001326" />
        </div>
      ) : (
        <>
          <button className="filtro-btn" onClick={toggleFiltro}><FaFilter /> Filtro</button>
          <div className={`filtros-container ${mostrarFiltros ? 'active' : ''}`}>
            <select className="filtro-select" value={categoriaFiltro} onChange={handleFiltroChange(setCategoriaFiltro)}>
              <option value="">Todas Categorias</option>
              {categorias.map(categoria => (
                <option key={categoria.tb04_id_categoria} value={categoria.tb04_id_categoria}>
                  {categoria.tb04_descricao}
                </option>
              ))}
            </select>
            <select className="filtro-select" value={odsFiltro} onChange={handleFiltroChange(setOdsFiltro)}>
              <option value="">Todos ODS</option>
              {ods.map(odsItem => (
                <option key={odsItem.tb05_id_ods} value={odsItem.tb05_id_ods}>
                  {odsItem.tb05_descricao}
                </option>
              ))}
            </select>
          </div>
          {filtrarProjetos()}
        </>
      )}
    </div>
  );
};
 
export default Projetos;