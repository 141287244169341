import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane, FaRobot, FaTimes, FaTrash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import axios from 'axios';
import DOMPurify from 'dompurify';
import './ChatIA.css';

function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);


  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    link.onload = () => {
      document.body.classList.add('font-loaded'); 
    };

    return () => {
      document.body.classList.remove('font-loaded');
      document.head.removeChild(link);
    };
  }, []);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const clearChat = () => {
    setMessages([]);
    setInputValue('');
  };

  const sendMessage = async () => {
    if (inputValue.trim() === '') return;

    const userMessage = {
      sender: 'user',
      text: inputValue,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue('');
    setIsTyping(true);

    const messageHistory = messages.map((message) => `${message.sender}: ${message.text}`).join('\n');

    try {
      const projectId = extractProjectIdFromURL();

      const requestData = {
        pergunta: inputValue,
        history: messageHistory,
        ...(projectId ? { id: projectId } : {}),
      };

      const endpoint = projectId
        ? 'https://api.topiainvest.com.br/api/ia/projetos'
        : 'https://api.topiainvest.com.br/api/ia';

      const response = await axios.post(endpoint, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const botMessage = {
        sender: 'bot',
        text: response.data.resposta.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') || 'Desculpe, não entendi a pergunta.',
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    } catch (error) {
      const errorMessage = {
        sender: 'bot',
        text: 'Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente.',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const extractProjectIdFromURL = () => {
    const url = window.location.pathname;
    const match = url.match(/\/projetos\/(\d+)/);
    return match ? match[1] : null;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const chatIconStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#001326',
    color: '#ffc85c',
    padding: '15px',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 1000,
    display: isOpen ? 'none' : 'block',
  };

  const chatContainerStyle = {
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    height: '500px',
    position: 'fixed',
    bottom: '40px',
    right: '20px',
    zIndex: 999,
    backgroundColor: '#f4f7f9',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    overflow: 'hidden',
  };

  const mobileStyle = {
    width: '100vw',
    height: '100vh',
    bottom: '0',
    right: '0',
  };

  const chatWindowStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
  };

  const clearButtonStyle = {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
  };

  const inputContainerStyle = {
    display: 'flex',
    padding: '10px',
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
  };

  const inputStyle = {
    flex: 1,
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '20px',
    marginRight: '10px',
    resize: 'none',
    height: 'auto',
    maxHeight: '150px',
  };

  const sendButtonStyle = {
    padding: '12px',
    backgroundColor: '#D9AD54',
    color: '#001326',
    borderRadius: '50%',
    cursor: 'pointer',
    border: 'none',
  };

  const messageStyle = {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'flex-end',
  };

  const userMessageStyle = {
    backgroundColor: '#D9AD54',
    color: '#001326',
    padding: '15px',
    borderRadius: '15px',
    maxWidth: '70%',
    alignSelf: 'flex-end',
  };

  const botMessageStyle = {
    backgroundColor: '#fff',
    color: '#001326',
    padding: '15px',
    borderRadius: '15px',
    border: '1px solid #D9AD54',
    maxWidth: '70%',
    alignSelf: 'flex-start',
    position: 'relative',
  };

  return (
    <>
      <div style={chatIconStyle} onClick={toggleChat}>
        <span className="material-icons-outlined" style={{ fontSize: '28px', color: '#ffc85c' }}>
          auto_awesome
        </span>
      </div>

      <div style={{ ...chatContainerStyle, ...(window.innerWidth < 600 ? mobileStyle : {}) }}>
        <header style={{ backgroundColor: '#001326', color: '#fff', padding: '15px', textAlign: 'center', position: 'relative' }}>
          topIA
          <button style={closeButtonStyle} onClick={toggleChat}>
            <FaTimes size={18} />
          </button>
          <button style={clearButtonStyle} onClick={clearChat}>
            <FaTrash size={18} />
          </button>
        </header>
        <div style={chatWindowStyle}>
          {messages.map((message, index) => (
            <motion.div
              key={index}
              style={{
                ...messageStyle,
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
              }}
              initial="hidden"
              animate="visible"
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              transition={{ duration: 0.3 }}
            >
              {message.sender === 'bot' ? (
                <div style={botMessageStyle}>
                  <FaRobot style={{ fontSize: '20px', position: 'absolute', top: '-10px', left: '-10px' }} />
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }} />
                </div>
              ) : (
                <div style={userMessageStyle}>{message.text}</div>
              )}
            </motion.div>
          ))}
          {isTyping && (
            <div className="typing-indicator">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div style={inputContainerStyle}>
          <textarea
            type="text"
            style={inputStyle}
            placeholder="Digite sua mensagem..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            rows={1}
          />
          <button style={sendButtonStyle} onClick={sendMessage}>
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </>
  );
}

export default ChatPage;