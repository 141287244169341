import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaRocket, FaTree, FaUsers, FaHistory } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
 
const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Container = styled.div`
  font-family: 'Arial', sans-serif;
  background: #f5f5f5; 
  color: #001326;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
  text-align: center;
  color: #001326;
  margin-bottom: 40px;
  animation: ${fadeIn} 1.5s ease-out;
  font-size: 2.5rem;
  font-weight: bold;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "card1 card2 card3"
      "card4 card4 card4";
  }
`;

const Section = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  color: #001326;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  animation: ${slideUp} 1s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;

  &:nth-child(4) {
    @media (min-width: 900px) {
      grid-column: 1 / -1;
    }
  }
`;

const Title = styled.h2`
  color: #ffc85c;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  font-size: 40px;
  color: #ffc85c;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
`;

const About = () => {
  return (
    <Container>
      <Header>Sobre a Topia Invest</Header>
      <Grid>
        <Section>
          <Icon><FaRocket /></Icon>
          <Title>Sobre a Plataforma</Title>
          <Text>Topia Invest permite cadastrar projetos focados em desenvolvimento sustentável, estabelecendo metas de arrecadação e atraindo financiadores comprometidos com os ODS.</Text>
        </Section>
        <Section>
          <Icon><FaTree /></Icon>
          <Title>Desenvolvimento Sustentável</Title>
          <Text>Nosso foco está em promover iniciativas que impactem positivamente o ambiente e a sociedade, alinhadas aos Objetivos de Desenvolvimento Sustentável da ONU.</Text>
        </Section>
        <Section>
          <Icon><FaHistory /></Icon>
          <Title>Nossa História</Title>
          <Text>A ideia de Topia Invest surgiu de um projeto de conclusão de curso por estudantes em Atibaia, visando apoiar e fomentar o financiamento de projetos sustentáveis.</Text>
        </Section>
        <Section>
          <Icon><FaUsers /></Icon>
          <Title>Participe</Title>
          <Text>Engaje-se com nossa comunidade e invista em projetos que estão moldando um futuro mais sustentável.</Text>
        </Section>
      </Grid>
    </Container>
  );
};

export default About;
