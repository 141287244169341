import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './MeusPagamentos.css';
import { BeatLoader } from 'react-spinners';

const MeusPagamentos = () => {
  const [pagamentos, setPagamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPagamentos = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/meus-pagamentos', {
          withCredentials: true,
        });
        const normalizedData = response.data.map(item => ({
          ...item,
          tb06_valor: parseFloat(item.tb06_valor)
        }));
        setPagamentos(normalizedData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchPagamentos();
  }, []);

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <BeatLoader color="#001326" />
    </div>
  );
  if (error) return <p>Error: {error}</p>;
 
  return (
    <div className="pagamentos-container">
      <h1 className="pagamentos-title">Meus Pagamentos</h1>
      <ul className="pagamentos-list">
        {pagamentos.map(pagamento => (
          <li key={pagamento.tb06_id} className="pagamentos-item">
            <Link to={`/projetos/${pagamento.tb03_id_projeto}`} className="pagamentos-link">
              <img src={pagamento.tb03_imagem} alt={pagamento.tb03_nome} className="pagamentos-image" />
              <div className="pagamentos-info">
                <span className="pagamentos-project-name">{pagamento.tb03_nome}</span>
                <span className="pagamentos-amount">R$ {pagamento.tb06_valor.toFixed(2)}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MeusPagamentos;
