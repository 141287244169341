import React, { Component } from 'react';
import SDG from 'alaya-sdgs';
import 'alaya-sdgs/dist/index.css';

class App extends Component {
  render() {
    return (
      <div>
        <h1>Objetivos de Desenvolvimento Sustentável</h1>
        {[...Array(17).keys()].map((goal) => (
          <SDG key={goal} goal={goal + 1} locale="br" />
        ))}
      </div>
    );
  }
}
 
export default App;
