import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Participacoes.css';
import { BeatLoader } from 'react-spinners';

const Participacoes = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/projetos/participacoes', {
          withCredentials: true,
        });
        setProjects(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <BeatLoader color="#001326" />
  </div>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="participacoes-container">
      <h1 className="participacoes-title">Participações</h1>
      <ul className="participacoes-list">
        {projects.map(project => (
          <li key={project.tb03_id_projeto} className="participacoes-item">
            <Link to={`/participacoes/${project.tb03_id_projeto}`} className="participacoes-link">
              <div>
                <img src={project.tb03_imagem} alt={project.tb03_nome} className="participacoes-image" />
                <span className="participacoes-name">{project.tb03_nome}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
 
export default Participacoes;
