import React from 'react';
import { FaHandPaper, FaSeedling, FaHandHoldingWater } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function CartaoSemente() {
  const navigate = useNavigate();

  const steps = [
    {
      icon: <FaHandPaper />,
      title: '1° Passo: Corte o Papel Semente',
      description: 'Corte o papel em pedacinhos. Corte com a mão, sem usar tesoura.',
    },
    {
      icon: <FaSeedling />,
      title: '2° Passo: Plante',
      description:
        'Em um vasinho, faça um buraquinho na terra e coloque o papel picado. Cubra com mais terra, porém uma camada fina e superficial. Outra forma de plantar é deixar o papel picado em um pratinho, borrifar com água e transportar para a terra quando as sementes tiverem germinado. O importante é manter a umidade do papel.',
    },
    {
      icon: <FaHandHoldingWater />,
      title: '3° Passo: Regue diariamente',
      description: 'Coloque um pouco de água todos os dias para manter o solo úmido.',
    },
  ];

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
  };

  const cardStyle = {
    width: '300px',
    margin: '15px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 10px 15px rgba(0,0,0,0.1)',
    textAlign: 'center',
  };

  const iconStyle = {
    fontSize: '60px',
    color: '#D9AD54',
    marginBottom: '20px',
  };

  const titleStyle = {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#001326',
  };

  const descriptionStyle = {
    fontSize: '16px',
    color: '#666',
  };

  const cardVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
      },
    },
    hover: {
      scale: 1.05,
      boxShadow: '0 20px 25px rgba(0,0,0,0.1)',
      transition: {
        duration: 0.3,
      },
    },
  };

  const topiaSectionStyle = {
    backgroundColor: '#001326',
    color: '#ffffff',
    padding: '50px 20px',
    textAlign: 'center',
  };

  const topiaTitleStyle = {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#D9AD54',
  };

  const topiaTextStyle = {
    fontSize: '18px',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.6',
  };

  const buttonStyle = {
    marginTop: '30px',
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: '#D9AD54',
    color: '#001326',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
  };

  const imageStyle = {
    width: '100%',
    maxWidth: '600px',
    margin: '40px auto 0',
  };

  const supportSectionStyle = {
    backgroundColor: '#fff',
    color: '#001326',
    padding: '50px 20px',
    textAlign: 'center',
  };

  const supportTitleStyle = {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#001326',
  };

  const supportTextStyle = {
    fontSize: '18px',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.6',
  };

  const supportButtonStyle = {
    marginTop: '30px',
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: '#D9AD54',
    color: '#001326',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
  };

  const supportImageStyle = {
    width: '100%',
    maxWidth: '600px',
    margin: '40px auto 0',
  };

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center', fontSize: '28px', marginBottom: '15px', color: '#001326', marginTop: '15px' }}>
        Como plantar o seu cartão
      </h2>

      <div style={containerStyle}>
        {steps.map((step, index) => (
          <motion.div
            key={index}
            style={cardStyle}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            whileHover="hover"
            variants={cardVariants}
          >
            <div style={iconStyle}>{step.icon}</div>
            <div style={titleStyle}>{step.title}</div>
            <div style={descriptionStyle}>{step.description}</div>
          </motion.div>
        ))}
      </div>

      <div style={topiaSectionStyle}>
        <motion.h2
          style={topiaTitleStyle}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Plante sua Ideia com a Topia Invest
        </motion.h2>
        <motion.p
          style={topiaTextStyle}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          Assim como uma pequena semente pode crescer, suas ideias
          podem ganhar vida com o apoio certo. Na <strong>Topia Invest</strong>, qualquer pessoa
          pode enviar seu projeto e compartilhá-lo com o mundo. Por meio do financiamento coletivo,
          transformamos sonhos em realidade, impulsionando inovação e sustentabilidade.
        </motion.p>
        <motion.button
          style={buttonStyle}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleNavigation('/')}
        >
          Conheça a Topia Invest
        </motion.button>
        <motion.img
          src="/img/logo_desk.png"
          alt="Topia Invest"
          style={imageStyle}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        />
      </div>

      <div style={supportSectionStyle}>
        <motion.h2
          style={supportTitleStyle}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Cultive Sonhos, Apoie Projetos
        </motion.h2>
        <motion.p
          style={supportTextStyle}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          Mesmo que você não tenha um projeto próprio, pode fazer a diferença regando as ideias de
          outros. Assim como uma semente precisa de água e cuidados para crescer, projetos inovadores
          precisam de apoio para florescer. Na <strong>Topia Invest</strong>, você pode financiar projetos
          que acredita e ajudar a transformar ideias em realidade.
        </motion.p>
        <motion.button
          style={supportButtonStyle}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleNavigation('/projetos')}
        >
          Apoiar Projetos
        </motion.button>
        <motion.img
          src="/img/logo_2.png"
          alt="Apoie Projetos na Topia Invest"
          style={supportImageStyle}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        />
      </div>
    </div>
  );
}

export default CartaoSemente;
