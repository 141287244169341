import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
 
const footerStyles = {
  bottom: '0',
  left: '0',
  width: '100%',
  backgroundColor: '#001326',
  color: '#ffffff',
  padding: '20px 0',
  textAlign: 'center',
};

const footerContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const footerTextStyles = {
  marginBottom: '10px',
  color: '#ffc85c',
};

const footerLinksStyles = {
  listStyle: 'none',
  padding: '0',
  margin: '10px 0',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const footerLinkItemStyles = {
  margin: '0 10px',
};

const footerLinkStyles = {
  color: '#ffffff',
  textDecoration: 'none',
  '@media (max-width: 600px)': {
    display: 'block', 
    textAlign: 'center', 
    margin: '5px 0'
  }
};

const footerSocialStyles = {
  color: '#ffffff',
  margin: '0 10px',
  fontSize: '24px',
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Footer = () => {
  return (
    <footer style={footerStyles}>
      <div style={footerContentStyles}>
        <div style={footerTextStyles}>
          <h3>Topia Invest</h3>
          <ul style={footerLinksStyles}>
          <li style={footerLinkItemStyles}><Link to="/criar-projeto/" style={footerLinkStyles} onClick={scrollToTop}>Criar Projeto</Link></li>
            <li style={footerLinkItemStyles}><Link to="/projetos/" style={footerLinkStyles} onClick={scrollToTop}>Projetos</Link></li>
            <li style={footerLinkItemStyles}><Link to="/blog/" style={footerLinkStyles} onClick={scrollToTop}>Blog</Link></li>
            <li style={footerLinkItemStyles}><Link to="/sobre/" style={footerLinkStyles} onClick={scrollToTop}>Sobre</Link></li>
            <li style={footerLinkItemStyles}><Link to="/contato/" style={footerLinkStyles} onClick={scrollToTop}>Contato</Link></li>
            <li style={footerLinkItemStyles}><Link to="/faq/" style={footerLinkStyles} onClick={scrollToTop}>FAQ</Link></li>
            <li style={footerLinkItemStyles}><Link to="/termos-de-uso/" style={footerLinkStyles} onClick={scrollToTop}>Termos de Uso</Link></li>
            <li style={footerLinkItemStyles}><Link to="/politica-de-privacidade/" style={footerLinkStyles} onClick={scrollToTop}>Política de Privacidade</Link></li>
          </ul>
        </div>
        <div>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={footerSocialStyles}>
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={footerSocialStyles}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={footerSocialStyles}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={footerSocialStyles}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
