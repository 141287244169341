import React from 'react';
 
const Termos = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '70%',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f5f5f5',
      lineHeight: '1.8',
      textAlign: 'justify'
    }}>
      <h1 style={{ color: '#333', fontSize: '24px', borderBottom: '1px solid #bbb', paddingBottom: '10px' }}>Termos de Uso - Topia Invest</h1>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>1. Introdução</h2>
      <p>Bem-vindo à Topia Invest! A Topia Invest é uma plataforma web que visa facilitar a conexão entre empreendedores e investidores interessados em projetos sustentáveis. Ao utilizar nosso site, você concorda com os termos e condições aqui descritos. É fundamental que todos os usuários leiam e compreendam estas regras para garantir uma experiência segura e positiva na plataforma.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>2. Definições</h2>
      <ul style={{ textAlign: 'left' }}>
        <li>Usuário: Qualquer pessoa que utiliza a plataforma, incluindo empreendedores, investidores e administradores.</li>
        <li>Projeto: Proposta submetida para financiamento na plataforma.</li>
        <li>Empreendedor: Usuário que submete um projeto para financiamento.</li>
        <li>Investidor: Usuário que contribui financeiramente para um projeto.</li>
        <li>Administrador: Usuário que realiza o controle do que é exibido e do funcionamento da plataforma.</li>
      </ul>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>3. Cadastro e Conta</h2>
      <p>Para se cadastrar, o usuário deve ter pelo menos 18 anos e possuir um CPF válido. O aceite dos termos de uso no momento do cadastro é obrigatório. O usuário é responsável por manter suas informações de conta seguras e confidenciais.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>4. Uso da Plataforma</h2>
      <p>Empreendedores podem cadastrar projetos, investidores podem financiar a partir de R$ 1,00, e administradores controlam a aprovação dos projetos. A legitimidade dos projetos é avaliada inicialmente pela plataforma, mas a responsabilidade final é dos investidores.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>5. Revisão e Aprovação de Projetos</h2>
      <p>Os projetos são revisados para garantir conformidade com os objetivos de sustentabilidade e critérios da plataforma. Projetos podem ser rejeitados com justificativas fornecidas para possíveis ajustes.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>6. Responsabilidade</h2>
      <p>A Topia Invest não se responsabiliza pela veracidade dos projetos ou pela segurança das transações financeiras. Qualquer disputa deve ser resolvida entre as partes envolvidas com suporte básico da plataforma.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>7. Direitos e Propriedade Intelectual</h2>
      <p>O conteúdo gerado pelos usuários é de sua propriedade, mas ao submeter um projeto, o usuário concede à Topia Invest o direito de usar e modificar esse conteúdo para operar a plataforma.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>8. Privacidade e Segurança</h2>
      <p>Os dados pessoais são coletados e utilizados conforme nossa <a href="https://topiainvest.com.br/politica-de-privacidade" style={{ color: '#001326' }}>Política de Privacidade</a>. Adotamos medidas de segurança para proteger as informações dos usuários.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>9. Alterações nos Termos de Uso</h2>
      <p>A Topia Invest pode modificar estes termos a qualquer momento. É responsabilidade do usuário revisar periodicamente os termos.</p>
      <h2 style={{ fontSize: '18px', color: '#444', marginTop: '20px', textAlign: 'left' }}>10. Contato</h2>
      <p>Para dúvidas ou suporte, entre em contato através do nosso <a href="https://topiainvest.com.br/contato" style={{ color: '#001326' }}>formulário de contato</a>.</p>
    </div>
  );
};

export default Termos;
