import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FaHeart, FaLeaf, FaGraduationCap, FaMars, FaWater, FaBolt, FaIndustry, FaCity, FaGlobe, FaTree, FaHandPeace, FaHandsHelping } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';
import 'react-toastify/dist/ReactToastify.css';
import './Criarprojeto.css';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import $ from 'jquery';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.js';
import { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const DetalhesAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null); 
  const [message, setMessage] = useState('');
  const [additionalImages, setAdditionalImages] = useState([{ file: null, preview: null }]); 
  const firebaseConfig = {
    apiKey: "AIzaSyB6YaK9zZw496-fIpJJWd0ljDz8gA3qLEY",
    authDomain: "topiainvest.firebaseapp.com",
    projectId: "topiainvest",
    storageBucket: "topiainvest.appspot.com",
    messagingSenderId: "620253874717",
    appId: "1:620253874717:web:5e7d759890de1436fa54f4",
    measurementId: "G-H11LC3M787"
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const handleDrop = (index) => (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const newImages = [...additionalImages];
      newImages[index] = {
        file,
        preview: URL.createObjectURL(file)
      };
      setAdditionalImages(newImages);
    }
  };
  
  const incrementImageFields = () => {
    if (imageFields < 3) {
      setImageFields(imageFields + 1);
      setAdditionalImages([...additionalImages, { file: null, preview: null }]);
    }
  };
  
  const renderDropzones = () => {
    return [...Array(imageFields)].map((_, i) => (
      <div className="form-step" key={i}>
        <label className="form-label">{`Imagem ${i + 1}`}</label>
        <div {...getRootProps(getDropzoneProps(i))} style={dropzoneStyle}>
          <input {...getInputProps()} />
          {additionalImages[i]?.preview ? (
            <img src={additionalImages[i].preview} alt="Preview" className="image-preview" />
          ) : formData[`imagemPreview${i + 1}`] ? (
            <img src={formData[`imagemPreview${i + 1}`]} alt="Preview" className="image-preview" />
          ) : (
            <div style={placeholderStyle}>
              <p>Arraste e solte uma imagem aqui, ou clique para selecionar uma</p>
            </div>
          )}
        </div>
      </div>
    ));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*'
  });

  const [formData, setFormData] = useState({
    nome: '',
    descricao: '',
    meta_liquida: '0.00',
    data_objetivo: '',
    imagem: null,
    imagemPreview1: null,
    imagemPreview2: null,
    imagemPreview3: null,
    categoria: '',
    ods: [],
    whatsapp: '',
    instagram: '',
    facebook: '',
    youtube: '',
    linkedin: '',
    x: '' 
  });
  const [transactionData, setTransactionData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [accessLogs, setAccessLogs] = useState([]);
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/categorias');
        setCategorias(response.data);
      } catch (error) {
        toast.error('Erro ao carregar categorias. Tente novamente.');
      }
    };
    fetchCategorias();
  }, []);
  const editorRef = useRef(null);
  useEffect(() => {
    $(editorRef.current).summernote({
      height: 300,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ],
      callbacks: {
        onBlur: function() {
          const newContent = $(editorRef.current).summernote('code');
          if (newContent !== formData.descricao) {
            setFormData(prevFormData => ({ ...prevFormData, descricao: newContent }));
          }
        }
      }
    });
  
    if (formData.descricao) {
      $(editorRef.current).summernote('code', formData.descricao);
    }
  
    return () => {
      $(editorRef.current).summernote('destroy');
    };
  }, [formData.descricao]);
  
  useEffect(() => { 
    const fetchProjeto = async () => {
      try {
        const response = await axios.get(`https://api.topiainvest.com.br/api/projetos/admin/buscar/${id}`, { withCredentials: true });
        const projeto = response.data;
    
        const existingImages = [
          { preview: projeto.tb03_imagem || null },
          { preview: projeto.tb03_imagem_2 || null },
          { preview: projeto.tb03_imagem_3 || null }
        ];
        
        const filteredImages = existingImages.filter(image => image.preview !== null);
    
        if (projeto) {
          setFormData({
            nome: projeto.tb03_nome,
            descricao: projeto.tb03_descricao,
            meta_liquida: projeto.tb03_meta_liquida,
            data_objetivo: projeto.tb03_data_objetivo.split('T')[0],
            imagemPreview1: projeto.tb03_imagem,  
            imagemPreview2: projeto.tb03_imagem_2,
            imagemPreview3: projeto.tb03_imagem_3,
            categoria: projeto.tb03_id_categoria,
            ods: projeto.tb03_id_ods.split(',').map(Number),
            whatsapp: projeto.tb03_whatsapp || '', 
            instagram: projeto.tb03_instagram || '',  
            facebook: projeto.tb03_facebook || '',  
            youtube: projeto.tb03_youtube || '',  
            linkedin: projeto.tb03_linkedin || '',  
            x: projeto.tb03_x || ''  
          });
          setStatus(projeto.tb03_id_status);
          setMessage(projeto.tb03_message);
          setAdditionalImages(filteredImages); 
          setImageFields(filteredImages.length || 1);
        } else {
          toast.error('Projeto não encontrado.');
          navigate(`/login`);          
        }
      } catch (error) {
        toast.error('Erro ao carregar projetos. Tente novamente.');
        navigate(`/login`);
      }
    };
    
    
    const fetchDetalhes = async () => {
      try {
        const response = await axios.get(`https://api.topiainvest.com.br/api/projeto/${id}/detalhes`, { withCredentials: true });
        const detalhes = response.data;
        setTransactionData(detalhes.transacoes);
        setAccessLogs(detalhes.logs);
      } catch (error) {
        toast.error('Erro ao carregar detalhes do projeto.');
      }
    };
  
    fetchProjeto();
    fetchDetalhes();
  }, [id]);
      const exportPDF = async () => {
        const doc = new jsPDF();
      
        const originalWidth = 499; 
        const originalHeight = 202;
      
        const imageWidth = 30; 
        const imageHeight = (originalHeight / originalWidth) * imageWidth;
      
        const pageWidth = doc.internal.pageSize.width;
        const x = (pageWidth - imageWidth) / 2;
      
        doc.setFillColor('#001326');
        doc.rect(0, 0, pageWidth, 30 + imageHeight, 'F');
      
        const logoUrl = `${window.location.origin}/img/logo_desk.png`;
      
        doc.addImage(logoUrl, 'PNG', x, 10, imageWidth, imageHeight); 
      
        doc.setFontSize(20);
        doc.setTextColor('#FFC85C'); 
        doc.text('Relatório de Financiadores', 10, 40);
      
        doc.setFontSize(16);
        doc.setTextColor('#000000');
        doc.text(formData.nome, 10, 50);
      
        const startY = 60;
      
        doc.autoTable({
          startY: startY,
          head: [['Nome', 'Email', 'Telefone', 'Valor (R$)', 'Data']],
          body: transactionData.map(trans => [
            `${trans.tb01_nome} ${trans.tb01_sobrenome}`,
            trans.tb01_email,
            trans.tb01_telefone, 
            `R$ ${parseFloat(trans.tb06_valor).toFixed(2)}`,
            new Date(trans.tb06_data).toLocaleDateString(),
          ]),
          styles: {
            fillColor: ['#001326', '#f2dbae'],
            textColor: '#fff'
          },
          headStyles: { fillColor: '#001326', textColor: '#FFC85C' }, 
          bodyStyles: { fillColor: '#f2dbae', textColor: '#001326' },
        });
      
        doc.save(`financiadores-${formData.nome}.pdf`);
      };
    
      const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
          transactionData.map(trans => ({
            Nome: `${trans.tb01_nome} ${trans.tb01_sobrenome}`,
            Email: trans.tb01_email,
            Telefone: trans.tb01_telefone,
            Valor: `R$ ${parseFloat(trans.tb06_valor).toFixed(2)}`,
            Data: new Date(trans.tb06_data).toLocaleDateString(),
          }))
        );
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Financiadores');
        XLSX.writeFile(wb, `financiadores-${formData.nome}.xlsx`);
      };
  
  const getAccessCount = (days) => {
    const now = new Date();
  
    return accessLogs.filter(log => {
      const logDate = new Date(log.tb11_access_time);
      const diffTime = now - logDate;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      
      if (days === 1) {
        return (
          logDate.getDate() === now.getDate() &&
          logDate.getMonth() === now.getMonth() &&
          logDate.getFullYear() === now.getFullYear()
        );
      }
      
      return diffDays <= days;
    }).length;
  };
  
  const totalFinanciado = transactionData.reduce((sum, trans) => sum + parseFloat(trans.tb06_valor), 0);
  const financiadoresCount = transactionData.length;
  
  const accessData = {
    total: accessLogs.length,
    last30Days: getAccessCount(30),
    last7Days: getAccessCount(7),
    today: getAccessCount(1)
  };

  const statusText = {
    1: 'Aguardando Aprovação',
    2: 'Aprovado',
    3: 'Rejeitado',
    4: 'Concluído'
  };
  
  const statusColor = {
    1: '#ffc85c',
    2: '#28a745',
    3: '#dc3545',
    4: '#17a2b8'
  };
  const odsHoverStyle = {

  };
  const odsImageSelectedStyle = {
    filter: 'none'
  };
  const odsItemImageStyle = {
    width: '100%',
    maxWidth: '200px',
    filter: 'grayscale(100%)',
    transition: 'filter 0.3s ease'
  };

  const odsItemStyle = {
    flex: '0 1 32%',
    marginBottom: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    padding: '0 5px' 
  };

  const ods2030 = [
    { id: 1, text: 'Acabar com a pobreza em todas as suas formas, em todos os lugares', icon: FaHeart },
    { id: 2, text: 'Acabar com a fome, alcançar a segurança alimentar e melhoria da nutrição e promover a agricultura sustentável', icon: FaLeaf },
    { id: 3, text: 'Assegurar uma vida saudável e promover o bem-estar para todos, em todas as idades', icon: FaHandPeace },
    { id: 4, text: 'Assegurar a educação inclusiva e equitativa e de qualidade, e promover oportunidades de aprendizagem ao longo da vida para todos', icon: FaGraduationCap },
    { id: 5, text: 'Alcançar a igualdade de gênero e empoderar todas as mulheres e meninas', icon: FaMars },
    { id: 6, text: 'Assegurar a disponibilidade e gestão sustentável da água e saneamento para todos', icon: FaWater },
    { id: 7, text: 'Assegurar o acesso confiável, sustentável, moderno e a preço acessível à energia para todos', icon: FaBolt },
    { id: 8, text: 'Promover o crescimento econômico sustentado, inclusivo e sustentável, emprego pleno e produtivo e trabalho decente para todos', icon: FaIndustry },
    { id: 9, text: 'Construir infraestruturas resilientes, promover a industrialização inclusiva e sustentável', icon: FaIndustry },
    { id: 10, text: 'Reduzir a desigualdade dentro dos países e entre eles', icon: FaGlobe },
    { id: 11, text: 'Tornar as cidades e os assentamentos humanos inclusivos, seguros, resilientes e sustentáveis', icon: FaCity },
    { id: 12, text: 'Assegurar padrões de produção e de consumo sustentáveis', icon: FaHandsHelping },
    { id: 13, text: 'Tomar medidas urgentes para combater a mudança do clima e seus impactos', icon: FaLeaf },
    { id: 14, text: 'Conservação e uso sustentável dos oceanos, dos mares e dos recursos marinhos para o desenvolvimento sustentável', icon: FaWater },
    { id: 15, text: 'Proteger, recuperar e promover o uso sustentável dos ecossistemas terrestres, gerir de forma sustentável as florestas, combater a desertificação, deter e reverter a degradação da terra e deter a perda de biodiversidade', icon: FaTree },
    { id: 16, text: 'Promover sociedades pacíficas e inclusivas para o desenvolvimento sustentável, proporcionar o acesso à justiça para todos e construir instituições eficazes, responsáveis e inclusivas em todos os níveis', icon: FaHandPeace },
    { id: 17, text: 'Fortalecer os meios de implementação e revitalizar a parceria global para o desenvolvimento sustentável', icon: FaHandsHelping }
  ];
  const [imageFields, setImageFields] = useState(1);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const adjustedOdsItemStyle = isMobile ? { ...odsItemStyle, flex: '0 1 48%' } : odsItemStyle;
  const renderOdsIcons = () => {
    return ods2030.map((ods, index) => (
      <div
        key={ods.id}
        style={formData.ods.includes(ods.id) ? {...adjustedOdsItemStyle, ...odsHoverStyle} : adjustedOdsItemStyle}
        onClick={() => handleOdsClick(ods.id)}
      >
        <img
          src={`/img/ods${ods.id}.png`}
          alt={`ODS ${ods.id}`}
          style={formData.ods.includes(ods.id) ? {...odsItemImageStyle, ...odsImageSelectedStyle} : odsItemImageStyle}
        />
      </div>
    ));
  };
  const [showAllFinancers, setShowAllFinancers] = useState(false);
  const financiamentoRowStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '20px',
  };
  
  const financersListStyle = {
    listStyleType: 'none',
    padding: 0,
    margin: '20px 0',
    overflowX: 'auto',
    whiteSpace: 'nowrap', 
  };
  
  const financerItemStyle = {
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '600px', 
  };
  
  const financerItemTextStyle = {
    flex: '1',
    minWidth: '100px', 
    textAlign: 'center', 
    padding: '0 10px',
    fontSize: '14px',
  };

  const loadMoreButtonStyle = {
    backgroundColor: '#4caf50',
    border: 'none',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '20px'
  };
  const displayedFinancers = showAllFinancers ? transactionData : transactionData.slice(0, 5);
  
  const handleLoadMore = () => {
    setShowAllFinancers(true);
  };
  const handleOdsClick = (odsId) => {
    setFormData(prevFormData => {
      const newOds = prevFormData.ods.includes(odsId)
        ? prevFormData.ods.filter(item => item !== odsId)
        : [...prevFormData.ods, odsId];
      return { ...prevFormData, ods: newOds };
    });
  };
  const addImageButtonStyle = {
    backgroundColor: '#ffc85c',
    color: '#001326',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
    fontWeight: 'bold',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px'
  };
  
  const handleMetaChange = (values) => {
    const { value } = values;
    setFormData({ ...formData, meta_liquida: value });
  };

  const incrementMeta = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      meta_liquida: (parseFloat(prevFormData.meta_liquida) + 50).toFixed(2)
    }));
  };

  const decrementMeta = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      meta_liquida: Math.max(0, parseFloat(prevFormData.meta_liquida) - 50).toFixed(2)
    }));
  };
  const formatSocialMediaUrl = (platform, usernameOrUrl) => {
    if (!usernameOrUrl) return null;
  
    if (usernameOrUrl.startsWith('http://') || usernameOrUrl.startsWith('https://')) {
      return usernameOrUrl;
    }
  
    switch (platform) {
      case 'instagram':
        return `https://www.instagram.com/${usernameOrUrl.replace('@', '')}`;
      case 'facebook':
        return `https://www.facebook.com/${usernameOrUrl}`;
      case 'youtube':
        return `https://www.youtube.com/${usernameOrUrl}`;
      case 'linkedin':
        return `https://www.linkedin.com/in/${usernameOrUrl}`;
      case 'x':
        return `https://twitter.com/${usernameOrUrl.replace('@', '')}`;
      default:
        return usernameOrUrl;
    }
  };

  const odsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '10px'
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedImages = [];

    for (let i = 0; i < 3; i++) {
      if (additionalImages[i]?.file) {
        const imageRef = ref(storage, `imagens/${id}-imagem${i + 1}-${additionalImages[i].file.name}`);
        const snapshot = await uploadBytes(imageRef, additionalImages[i].file);
        const url = await getDownloadURL(snapshot.ref);
        uploadedImages.push(url);
      } else if (formData[`imagemPreview${i + 1}`]) {
        uploadedImages.push(formData[`imagemPreview${i + 1}`]);
      } else {
        uploadedImages.push(null);
      }
    }
    
    const { nome, descricao, meta_liquida, data_objetivo, imagem, categoria, ods } = formData;
    if (status === 3 && !message.trim()) {
      toast.error('Por favor, preencha a mensagem para status "Rejeitado".');
      return;
    }
  
    let imageUrl = formData.imagemPreview;
    if (imagem && imagem instanceof File) {
      const imageRef = ref(storage, `imagens/${imagem.name}`);
      try {
        const snapshot = await uploadBytes(imageRef, imagem);
        imageUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error('Erro no upload da imagem: ', error);
        toast.error('Erro ao atualizar projeto. Tente novamente.');
        return;
      }
    }
  
  const formDataToSend = {
    nome: formData.nome,
    descricao: formData.descricao,
    meta_liquida: formData.meta_liquida,
    data_objetivo: formData.data_objetivo,
    imagem: uploadedImages[0] || null,
    imagem2: uploadedImages[1] || null,
    imagem3: uploadedImages[2] || null,
    categoria: formData.categoria,
    ods: formData.ods.join(','),
    status,
    message,
    whatsapp: formData.whatsapp || null,
    instagram: formatSocialMediaUrl('instagram', formData.instagram),
    facebook: formatSocialMediaUrl('facebook', formData.facebook),
    youtube: formatSocialMediaUrl('youtube', formData.youtube),
    linkedin: formatSocialMediaUrl('linkedin', formData.linkedin),
    x: formatSocialMediaUrl('x', formData.x) 
  };
  
    try {
      const response = await axios.post(`https://api.topiainvest.com.br/api/atualizar_projeto_admin/${id}`, formDataToSend, { withCredentials: true });
      if (response.status === 200) {
        toast.success('Projeto atualizado com sucesso!');
        setTimeout(() => {
          navigate('/painel-admin');
        }, 3000);
      }
    } catch (error) {
      console.error('Erro ao atualizar projeto: ', error);
      toast.error('Erro ao atualizar projeto. Tente novamente.');
    }
  };

  return (
<div className="container-cadastro">
<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<div className="dashboard-panel">
  <h2>Painel de Estatísticas</h2>
  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Total de Acessos</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>{accessData.total}</p>
    </div>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Acessos nos últimos 30 dias</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>{accessData.last30Days}</p>
    </div>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Acessos nos últimos 7 dias</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>{accessData.last7Days}</p>
    </div>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Acessos Hoje</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>{accessData.today}</p>
    </div>
  </div>

  <h2>Painel de Financiamento</h2>
  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Total Financiado (R$)</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>R$ {totalFinanciado.toFixed(2)}</p>
    </div>
    <div style={{ flex: '1', minWidth: '200px', borderRadius: '10px', border: '1px solid #e0e0e0', padding: '15px', textAlign: 'center', backgroundColor: '#fff' }}>
      <h5>Número de Financiadores</h5>
      <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#4caf50' }}>{financiadoresCount}</p>
    </div>
  </div>
  {transactionData.length > 0 && (
  <>
    <h2>Financiadores Recentes</h2>
    <div style={{ overflowX: 'auto' }}> 
      <ul style={financersListStyle}>
        {displayedFinancers.map((trans, index) => (
          <li key={index} style={financerItemStyle}>
            <strong style={financerItemTextStyle}>{`${trans.tb01_nome} ${trans.tb01_sobrenome}`}</strong>
            <span style={financerItemTextStyle}>{`${trans.tb01_email}`}</span>
            <span style={financerItemTextStyle}>R$ {parseFloat(trans.tb06_valor).toFixed(2)}</span>
            <span style={financerItemTextStyle}>{new Date(trans.tb06_data).toLocaleDateString()}</span>
          </li>
        ))}
      </ul>
    </div>

    {transactionData.length > 5 && !showAllFinancers && (
      <button style={loadMoreButtonStyle} onClick={handleLoadMore}>
        Ver Todos
      </button>
    )}
                              <div className="export-buttons" style={{ marginBottom: '20px', textAlign: 'right' }}>
        <button onClick={exportPDF} className="button" style={{ marginRight: '10px', backgroundColor: '#ffc85c', border: 'none', color: '#001326', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }}>
          Exportar para PDF
        </button>
        <button onClick={exportExcel} className="button" style={{ backgroundColor: '#ffc85c', border: 'none', color: '#001326', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', fontWeight: 'bold' }}>
          Exportar para Excel
        </button>
      </div>
  </>
)}
</div>
  <h2>Detalhes do Projeto</h2>
  
  <form onSubmit={handleSubmit}>
    <div className="form-step">
      <label className="form-label">Status do Projeto</label>
      <select
        name="status"
        value={status}
        onChange={(e) => setStatus(parseInt(e.target.value))}
        className="form-select"
        required
      >
        {Object.keys(statusText).map(key => (
          <option key={key} value={key}>{statusText[key]}</option>
        ))}
      </select>
    </div>
    <div className="form-step">
      <label className="form-label">Mensagem</label>
      <textarea
        name="mensagem"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="form-textarea"
        rows="3"
      ></textarea>
    </div>
        <div className="form-step">
          <label className="form-label">Nome do Projeto</label>
          <input
            type="text"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-step">
        <label className="form-label">Descrição</label>
        <div ref={editorRef} className="form-textarea"></div>
      </div>
        <div className="form-step">
          <label className="form-label">Meta de Arrecadação (R$)</label>
          <div className="meta-arrecadacao">
            <button type="button" className="button-meta" onClick={decrementMeta}>-</button>
            <NumericFormat
              value={formData.meta_liquida}
              displayType={'input'}
              thousandSeparator={true}
              prefix={'R$ '}
              decimalScale={2}
              fixedDecimalScale={true}
              className="form-input meta-input"
              onValueChange={handleMetaChange}
            />
            <button type="button" className="button-meta" onClick={incrementMeta}>+</button>
          </div>
        </div>
        <div className="form-step">
  <label className="form-label">Meta com Desconto de Taxas</label>
  <div className="meta-arrecadacao-desconto">
    <span>Você receberá:</span>
    <NumericFormat
      value={(parseFloat(formData.meta_liquida) * 0.9).toFixed(2)}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'R$ '}
      className="form-input meta-input"
    />
  </div>
</div>
        <div className="form-step">
          <label className="form-label">Data Limite de Arrecadação</label>
          <input
            type="date"
            name="data_objetivo"
            value={formData.data_objetivo}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-step">
  <label className="form-label">WhatsApp</label>
  <input
    type="text"
    name="whatsapp"
    value={formData.whatsapp}
    onChange={handleChange}
    className="form-input"
    placeholder="Número de WhatsApp (somente números)"
  />
</div>

<div className="form-step">
  <label className="form-label">Instagram</label>
  <input
    type="text"
    name="instagram"
    value={formData.instagram}
    onChange={handleChange}
    className="form-input"
    placeholder="Instagram (@username)"
  />
</div>

<div className="form-step">
  <label className="form-label">Facebook</label>
  <input
    type="text"
    name="facebook"
    value={formData.facebook}
    onChange={handleChange}
    className="form-input"
    placeholder="Facebook (username ou URL)"
  />
</div>

<div className="form-step">
  <label className="form-label">YouTube</label>
  <input
    type="text"
    name="youtube"
    value={formData.youtube}
    onChange={handleChange}
    className="form-input"
    placeholder="YouTube (canal ou URL)"
  />
</div>

<div className="form-step">
  <label className="form-label">LinkedIn</label>
  <input
    type="text"
    name="linkedin"
    value={formData.linkedin}
    onChange={handleChange}
    className="form-input"
    placeholder="LinkedIn (perfil ou URL)"
  />
</div>

<div className="form-step">
  <label className="form-label">X (Twitter)</label>
  <input
    type="text"
    name="x"
    value={formData.x}
    onChange={handleChange}
    className="form-input"
    placeholder="X (Twitter) (@username)"
  />
</div>
        <div className="form-step">
  <label className="form-label">Imagens do Projeto</label>
  
  {additionalImages.slice(0, imageFields).map((_, index) => (
    <div key={index} {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {formData[`imagemPreview${index + 1}`] ? (
        <img src={formData[`imagemPreview${index + 1}`]} alt={`Preview ${index + 1}`} className="image-preview" />
      ) : additionalImages[index]?.preview ? (
        <img src={additionalImages[index].preview} alt={`Preview ${index + 1}`} className="image-preview" />
      ) : (
        <p>Arraste e solte uma imagem aqui, ou clique para selecionar uma</p>
      )}
    </div>
  ))}

  {imageFields < 3 && (
    <button
      type="button"
      onClick={incrementImageFields}
      style={addImageButtonStyle}
    >
      Adicionar Nova Imagem
    </button>
  )}
</div>
        <div className="form-step">
          <label className="form-label">Categoria</label>
          <select
            name="categoria"
            value={formData.categoria}
            onChange={handleChange}
            className="form-select"
            required
          >
            <option value="">Selecione uma categoria</option>
            {categorias.map(cat => (
              <option key={cat.tb04_id_categoria} value={cat.tb04_id_categoria}>{cat.tb04_descricao}</option>
            ))}
          </select>
        </div>
        <div className="form-step">
          <label className="form-label">ODS 2030</label>
          <div style={odsContainerStyle}>
            {renderOdsIcons()}
  </div>
        </div>
        <div className="button-group">
          <button type="submit" className="button">Atualizar Projeto</button>
        </div>
      </form>
    </div>
  );
};

export default DetalhesAdmin;
