import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
    const [novaSenha, setNovaSenha] = useState('');
    const [repetirSenha, setRepetirSenha] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

  const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validatePassword = (password) => {
    return passwordCriteria.test(password);
  };

  const handleResetPassword = async () => {
    if (!validatePassword(novaSenha)) {
      toast.error('A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial.');
      return;
    }

    if (novaSenha !== repetirSenha) {
      toast.error('As senhas não coincidem.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://api.topiainvest.com.br/api/reset-password', {
        token,  
        novaSenha
      });

      toast.success('Senha redefinida com sucesso!');
      setNovaSenha('');
      setRepetirSenha('');
    } catch (error) {
      toast.error('Erro ao redefinir senha. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reset-password-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover draggable />
      <h2>Redefinir Senha</h2>

      <div className="form-group">
        <label htmlFor="novaSenha">Nova Senha</label>
        <div className="input-wrapper">
          <input
            type={showPassword ? 'text' : 'password'}
            id="novaSenha"
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
            className="form-input"
            placeholder="Digite sua nova senha"
          />
          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <small className="password-criteria">
          A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
        </small>
      </div>

      <div className="form-group">
        <label htmlFor="repetirSenha">Repetir Senha</label>
        <div className="input-wrapper">
          <input
            type={showRepeatPassword ? 'text' : 'password'}
            id="repetirSenha"
            value={repetirSenha}
            onChange={(e) => setRepetirSenha(e.target.value)}
            className="form-input"
            placeholder="Repita sua nova senha"
          />
          <span className="password-toggle-icon" onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
            {showRepeatPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
      </div>

      <button
        onClick={handleResetPassword}
        className="submit-button"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Redefinindo...' : 'Redefinir Senha'}
      </button>
    </div>
  );
};
 
export default ResetPassword;
