import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { FaWhatsapp, FaInstagram, FaFacebook, FaYoutube, FaLinkedin, FaHeart  } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { NumericFormat } from 'react-number-format';
import 'react-toastify/dist/ReactToastify.css';
import './Criarprojeto.css';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.js';
import { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { v4 as uuidv4 } from 'uuid';

const CriarProjeto = ({ user }) => {
  const navigate = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyB6YaK9zZw496-fIpJJWd0ljDz8gA3qLEY",
    authDomain: "topiainvest.firebaseapp.com",
    projectId: "topiainvest",
    storageBucket: "topiainvest.appspot.com",
    messagingSenderId: "620253874717",
    appId: "1:620253874717:web:5e7d759890de1436fa54f4",
    measurementId: "G-H11LC3M787"
  };

  const validateForm = () => {
    let missingFields = [];
    if (!formData.nome.trim()) missingFields.push('nome do projeto');
    if (!formData.descricao.trim()) missingFields.push('descrição');
    if (!formData.meta_liquida.trim() || parseFloat(formData.meta_liquida) === 0) missingFields.push('meta de arrecadação');
    if (!formData.data_objetivo.trim()) missingFields.push('data limite de arrecadação');
    if (!formData.categoria.trim()) missingFields.push('categoria');
    if (formData.ods.length === 0) missingFields.push('ODS 2030');
    if (additionalImages.length === 0 || additionalImages.some(img => !img.preview)) missingFields.push('imagem do projeto');
  
    return missingFields;
  };

  
  useEffect(() => {
    if (!user) {
      toast.info('Por favor, faça o login ou cadastre-se para participar do financiamento.');
      navigate(`/login?next=/criar-projeto`);
    }
  }, [user, navigate]);

  const odsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '10px'
  };
  
  const odsItemStyle = {
    flex: '0 1 32%',
    marginBottom: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    padding: '0 5px' 
  };
  
  const odsItemImageStyle = {
    width: '100%',
    maxWidth: '200px',
    filter: 'grayscale(100%)',
    transition: 'filter 0.3s ease'
  };
  
  const odsImageSelectedStyle = {
    filter: 'none'
  };
  
  const odsHoverStyle = {

  };
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const adjustedOdsItemStyle = isMobile ? { ...odsItemStyle, flex: '0 1 48%' } : odsItemStyle;  
  
  const renderOdsIcons = () => {
    return ods2030.map((ods, index) => (
      <div
        key={ods.id}
        style={formData.ods.includes(ods.id) ? {...adjustedOdsItemStyle, ...odsHoverStyle} : adjustedOdsItemStyle}
        onClick={() => handleOdsClick(ods.id)}
      >
        <img
          src={`/img/ods${ods.id}.png`}
          alt={`ODS ${ods.id}`}
          style={formData.ods.includes(ods.id) ? {...odsItemImageStyle, ...odsImageSelectedStyle} : odsItemImageStyle}
        />
      </div>
    ));
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const [additionalImages, setAdditionalImages] = useState([]);
  const [imageFields, setImageFields] = useState(1);
  
  const handleDrop = (index) => (acceptedFiles) => {
    console.log(acceptedFiles); 
  
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; 
      if (file) {
        const newImages = [...additionalImages];
        newImages[index] = {
          file,
          preview: URL.createObjectURL(file),
          uniqueName: uuidv4() + '-' + file.name
        };
        setAdditionalImages(newImages);
      } else {
        toast.error('Nenhum arquivo válido foi carregado.');
      }
    } else {
      toast.error('Nenhum arquivo detectado. Por favor, tente novamente.');
    }
  };
  
  const incrementImageFields = () => {
    if (imageFields < 3) {
      setImageFields(imageFields + 1);
      setAdditionalImages([...additionalImages, { file: null, preview: null }]);
    }
  };
  
  const getDropzoneProps = (index) => ({
    onDrop: handleDrop(index),
    accept: 'image/*'
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: handleDrop(imageFields - 1),
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        toast.error(`Arquivo rejeitado: ${file.file.name}`);
      });
    }
  });
  const renderDropzones = () => {
    return [...Array(imageFields)].map((_, i) => (
      <div className="form-step" key={i}>
        <label className="form-label">Imagem do Projeto</label>
        <p style={subStyle}>Adicione uma imagem ao seu projeto! Para adicionar mais imagens, clique em "Adicionar Nova Imagem". Até 3 imagens.</p>
        <div {...getRootProps(getDropzoneProps(i))} style={dropzoneStyle}>
          <input {...getInputProps()} />
          {additionalImages[i]?.preview ? (
            <img src={additionalImages[i].preview} alt="Preview" className="image-preview" />
          ) : (
            <div style={placeholderStyle}>
              <p>Arraste e solte uma imagem aqui, ou clique para selecionar uma</p>
            </div>
          )}
        </div>
      </div>
    ));
  };
  
  const dropzoneStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#cccccc',
    borderStyle: 'dashed',
    backgroundColor: '#f8f8f8',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '200px', 
    justifyContent: 'center'
  };
  

  const placeholderStyle = {
    textAlign: 'center',
    color: '#aaaaaa'
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    descricao: '',
    meta_liquida: '0.00',
    data_objetivo: '',
    imagem: null,
    imagemPreview: null,
    categoria: '',
    ods: [],
    whatsapp: '',
    instagram: '',
    facebook: '',
    youtube: '',
    twitter: '',
    linkedin: ''
  });

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const renderSocialFields = () => {
    return (
      <div className="form-step">
        <label className="form-label">Redes Sociais (Opcional)</label>
        <p style={subStyle}>Informe suas redes sociais para divulgar seu projeto!</p>
        
        <div className="social-fields">
          <div className="social-field">
            <FaWhatsapp style={iconStyle} />
            <input
              type="text"
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleSocialChange}
              placeholder="Número de WhatsApp (somente números)"
              className="form-input"
            />
          </div>
          <div className="social-field">
            <FaInstagram style={iconStyle} />
            <input
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleSocialChange}
              placeholder="Instagram (@username)"
              className="form-input"
            />
          </div>
          <div className="social-field">
            <FaFacebook style={iconStyle} />
            <input
              type="text"
              name="facebook"
              value={formData.facebook}
              onChange={handleSocialChange}
              placeholder="Facebook (username ou URL)"
              className="form-input"
            />
          </div>
          <div className="social-field">
            <FaYoutube style={iconStyle} />
            <input
              type="text"
              name="youtube"
              value={formData.youtube}
              onChange={handleSocialChange}
              placeholder="YouTube (canal ou URL)"
              className="form-input"
            />
          </div>
          <div className="social-field">
            <FaXTwitter style={iconStyle} />
            <input
              type="text"
              name="twitter"
              value={formData.twitter}
              onChange={handleSocialChange}
              placeholder="X (Twitter) (@username)"
              className="form-input"
            />
          </div>
          <div className="social-field">
            <FaLinkedin style={iconStyle} />
            <input
              type="text"
              name="linkedin"
              value={formData.linkedin}
              onChange={handleSocialChange}
              placeholder="LinkedIn (perfil ou URL)"
              className="form-input"
            />
          </div>
        </div>
      </div>
    );
  };
  
  const iconStyle = {
    marginRight: '10px',
    fontSize: '1.5rem',
    color: '#001326'
  };

  const subStyle = {
    color: '#6c757d',
    fontSize: '0.85rem',
    marginTop: '-5px',
    marginBottom: '10px',
    fontStyle: 'italic',
  };
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/categorias');
        setCategorias(response.data);
      } catch (error) {
        toast.error('Erro ao carregar categorias. Tente novamente.');
      }
    };
    fetchCategorias();
  }, []);

  const editorRef = useRef(null);
  useEffect(() => {
    $(editorRef.current).summernote({
      height: 300, 
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ],
      callbacks: {
        onChange: function(contents) {
          setFormData(prevFormData => ({ ...prevFormData, descricao: contents }));
        }
      }
    });
  }, []);

  const ods2030 = [
    { id: 1},
    { id: 2},
    { id: 3},
    { id: 4},
    { id: 5},
    { id: 6},
    { id: 7},
    { id: 8},
    { id: 9},
    { id: 10},
    { id: 11},
    { id: 12},
    { id: 13},
    { id: 14},
    { id: 15},
    { id: 16},
    { id: 17}
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, imagem: e.target.files[0], imagemPreview: URL.createObjectURL(e.target.files[0]) });
  };

  const handleOdsClick = (odsId) => {
    setFormData(prevFormData => {
      const newOds = prevFormData.ods.includes(odsId)
        ? prevFormData.ods.filter(item => item !== odsId)
        : [...prevFormData.ods, odsId];
      return { ...prevFormData, ods: newOds };
    });
  };

  const handleMetaChange = (values) => {
    const { value } = values;
    setFormData({ ...formData, meta_liquida: value });
  };

  const incrementMeta = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      meta_liquida: (parseFloat(prevFormData.meta_liquida) + 50).toFixed(2)
    }));
  };

  const decrementMeta = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      meta_liquida: Math.max(0, parseFloat(prevFormData.meta_liquida) - 50).toFixed(2)
    }));
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);

  const missingFields = validateForm();
  if (missingFields.length > 0) {
    toast.error(`Por favor, complete os campos faltantes: ${missingFields.join(', ')}.`);
    setIsSubmitting(false);
    return;
  }

  try {
    const socialLinks = {
      whatsapp: formData.whatsapp ? `https://wa.me/${formData.whatsapp}` : null,
      instagram: formData.instagram ? `https://instagram.com/${formData.instagram.replace('@', '')}` : null,
      facebook: formData.facebook ? (formData.facebook.startsWith('http') ? formData.facebook : `https://facebook.com/${formData.facebook}`) : null,
      youtube: formData.youtube ? (formData.youtube.startsWith('http') ? formData.youtube : `https://youtube.com/${formData.youtube}`) : null,
      twitter: formData.twitter ? `https://twitter.com/${formData.twitter.replace('@', '')}` : null,
      linkedin: formData.linkedin ? (formData.linkedin.startsWith('http') ? formData.linkedin : `https://linkedin.com/in/${formData.linkedin}`) : null,
    };


      const uploadedImages = await Promise.all(
        additionalImages.filter(img => img.file).map(async (img) => {
          const imageRef = ref(storage, `imagens/${img.uniqueName}`); 
          const snapshot = await uploadBytes(imageRef, img.file);
          return getDownloadURL(snapshot.ref);
        })
      );
  
    const formDataToSend = new FormData();
    formDataToSend.append('nome', formData.nome);
    formDataToSend.append('descricao', formData.descricao);
    formDataToSend.append('meta_liquida', formData.meta_liquida);
    formDataToSend.append('data_objetivo', formData.data_objetivo);
    uploadedImages.forEach((url, index) => formDataToSend.append(`imagem${index + 1}`, url));
    formDataToSend.append('categoria', formData.categoria);
    formDataToSend.append('ods', formData.ods.join(','));
    Object.keys(socialLinks).forEach(key => {
      if (socialLinks[key]) {
        formDataToSend.append(key, socialLinks[key]);
      }
    });
  
      const response = await axios.post('https://api.topiainvest.com.br/api/projeto', formDataToSend);
      if (response.status === 200) {
        toast.success('Projeto criado com sucesso! Enviado para análise.');
        setIsSubmitting(false);
        setTimeout(() => navigate('/meus-projetos'), 3000);
      }
    } catch (error) {
      console.error('Erro no upload da imagem: ', error);
      toast.error('Erro ao criar projeto. Tente novamente.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container-cadastro">
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <h2>Criar Projeto</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-step">
          <label className="form-label">Nome do Projeto</label>
          <p style={subStyle}>Digite o nome do seu projeto.</p>
          <input
            type="text"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        <div className="form-step">
          <label className="form-label">Descrição</label>
          <p style={subStyle}>Descreva seu projeto, personalize seu texto, use imagens e vídeos!</p>          
          <div ref={editorRef} className="form-textarea"></div>
        </div>
        <div className="form-step">
          <label className="form-label">Meta de Arrecadação (R$)</label>
          <p style={subStyle}>Digite o valor que deseja arrecadar.</p>
          <div className="meta-arrecadacao">
            <button type="button" className="button-meta" onClick={decrementMeta}>-</button>
            <NumericFormat
              value={formData.meta_liquida}
              displayType={'input'}
              thousandSeparator={true}
              prefix={'R$ '}
              decimalScale={2}
              fixedDecimalScale={true}
              className="form-input meta-input"
              onValueChange={handleMetaChange}
            />
            <button type="button" className="button-meta" onClick={incrementMeta}>+</button>
          </div>
        </div>
        <div className="form-step">
  <label className="form-label">Meta com Desconto de Taxas</label>
  <p style={subStyle}>Este é o valor que você receberá após o desconto de nossas taxas.</p>
  <div className="meta-arrecadacao-desconto">
    <span>Você receberá:</span>
    <NumericFormat
      value={(parseFloat(formData.meta_liquida) * 0.9).toFixed(2)}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'R$ '}
      className="form-input meta-input"
    />
  </div>
</div>

        <div className="form-step">
          <label className="form-label">Data Limite de Arrecadação</label>
          <p style={subStyle}>Data em que seu projeto expira.</p>
          <input
            type="date"
            name="data_objetivo"
            value={formData.data_objetivo}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>
        {renderSocialFields()}
        {renderDropzones()}
        {imageFields < 3 && (
  <button
    type="button"
    onClick={incrementImageFields}
    style={{
      backgroundColor: '#ffc85c',
      color: '#001326',
      border: 'none',
      padding: '10px 20px',
      cursor: 'pointer',
      borderRadius: '5px',
      fontWeight: 'bold',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: '10px'
    }}
  >
    Adicionar Nova Imagem
  </button>
)}
        <div className="form-step">
          <label className="form-label">Categoria</label>
          <p style={subStyle}>Escolha a categoria que seu projeto se encaixa.</p>
          <select
            name="categoria"
            value={formData.categoria}
            onChange={handleChange}
            className="form-select"
            required
          >
            <option value="">Selecione uma categoria</option>
            {categorias.map(cat => (
              <option key={cat.tb04_id_categoria} value={cat.tb04_id_categoria}>{cat.tb04_descricao}</option>
            ))}
          </select>
        </div>
        <div className="form-step">
          <label className="form-label">ODS 2030</label>
          <p style={subStyle}>Esolha um ou mais Objetivo de Desenvolvimento Sustentável que seu projeto promove!</p>
          <div style={odsContainerStyle}>
              {renderOdsIcons()}
            </div>
        </div>
        <div className="button-group">
        <button type="submit" className="button" disabled={isSubmitting} style={{ backgroundColor: isSubmitting ? '#ccc' : '' }}>Criar Projeto</button>
        </div>
      </form>
    </div>
  );
};

export default CriarProjeto;
