import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Financiarprojeto.css';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { FaWallet, FaCreditCard } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';

const FinanciarProjeto = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projeto, setProjeto] = useState(null);
  const [donationAmount, setDonationAmount] = useState('');
  const [paymentData, setPaymentData] = useState(null);
  const [forceKey, setForceKey] = useState(0);
  initMercadoPago('APP_USR-94b3e94d-5009-48d1-b591-fdc31980d184');
  useEffect(() => {
    if (!user) {
      toast.info('Por favor, faça o login ou cadastre-se para participar do financiamento.');
      navigate(`/login?next=/projetos/${id}`);
    }
  }, [user, navigate, id]);

  if (!user) {
    return null;
  }
  useEffect(() => {
    const fetchProjeto = async () => {
      try {
        const response = await axios.get(`https://api.topiainvest.com.br/api/ver_projetos/id/${id}`);
        setProjeto(response.data);
      } catch (error) {
        toast.error('Erro ao carregar o projeto. Tente novamente.');
      }
    };

    fetchProjeto();
  }, [id, forceKey]);

  const handleDonationChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ''); 
    setDonationAmount(value);
  };

  const handleFinanciar = async () => {
    if (parseFloat(donationAmount) <= 0) {
      toast.error('Insira um valor válido para a doação.');
      return;
    }

    const description = `${user.tb01_id}-${id}-${new Date().toISOString()}`;
    const paymentData = {
      transaction_amount: parseFloat(donationAmount),
      description: description,
      email: user.tb01_email,
      CPF: user.tb01_cpf
    };

    try {
      const response = await axios.post('https://api.topiainvest.com.br/api/pagar', paymentData);
      setPaymentData(response.data);
      toast.success('Doação iniciada com sucesso!');
    } catch (error) {
      toast.error('Erro ao processar a doação. Tente novamente.');
    }
  };

  const handlePagamentoEfetuado = () => {
    navigate('/pagamentos');
  };

  const handlePagarComCartao = () => {
    const donationAmountNumber = parseFloat(donationAmount);
  
    if (isNaN(donationAmountNumber) || donationAmountNumber <= 0) {
      toast.error('Insira um valor válido para a doação.');
      return;
    }
  
    const description = `${user.tb01_id}-${id}-${new Date().toISOString()}`;
    navigate(`/cartao?key=${new Date().getTime()}`, {
      state: {
        amount: donationAmountNumber,
        cardholderEmail: user.tb01_email,
        description: description,
        projectImage: projeto.tb03_imagem,
        projectName: projeto.tb03_nome
      }
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Código PIX copiado para a área de transferência!');
  };

  if (!projeto) {
    return     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <BeatLoader color="#001326" />
  </div>
  }

  return (
    <div className="financiar-projeto">
      <ToastContainer />
      {!paymentData ? (
        <>
          <div className="projeto-header">
            <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} className="projeto-imagem" />
            <h1>{projeto.tb03_nome}</h1>
          </div>
          <div className="doacao-section">
            <label htmlFor="donationAmount">Valor da Contribuição (R$):</label>
            <input
  type="text"
  id="donationAmount"
  value={`R$ ${donationAmount}`}
  onChange={handleDonationChange}
  placeholder="R$ 0,00"
/>

<div className="button-container">
<button onClick={handleFinanciar} className="financiar-button">
                <FaWallet className="button-icon" /> Pagar com Pix
              </button>
              <button onClick={handlePagarComCartao} className="financiar-button">
                <FaCreditCard className="button-icon" /> Pagar com Cartão
              </button>
</div>
          </div>
        </>
      ) : (
        <div className="payment-info">
          <h2>Valor: R$ {paymentData.transaction_amount}</h2>
          <h2>PIX Copia e Cola</h2>
          <p onClick={() => copyToClipboard(paymentData.point_of_interaction.transaction_data.qr_code)} className="pix-copia-e-cola">
            {paymentData.point_of_interaction.transaction_data.qr_code}
          </p>
          <h2>Data de Expiração: {new Date(paymentData.date_of_expiration).toLocaleString()}</h2>
          <img src={`data:image/png;base64,${paymentData.point_of_interaction.transaction_data.qr_code_base64}`} alt="QR Code para pagamento" />
          <button onClick={handlePagamentoEfetuado} className="pagamento-button">Efetuei o Pagamento</button>
        </div>
      )}
    </div>
  );
};
 
export default FinanciarProjeto;
