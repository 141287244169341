import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import './CarouselComponent.css';
import 'slick-carousel/slick/slick.css';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners'; 
import About  from './Sobre';

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

const CarouselComponent = () => {
  const sliderRef = useRef(null);
  const [projetos, setProjetos] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const fetchProjetos = async () => {
      try {
        const response = await axios.get('https://api.topiainvest.com.br/api/ver_projetos');
        setProjetos(response.data.slice(0, 3)); 
        setCarregando(false);
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
        setCarregando(false);
      }
    };
    fetchProjetos();
  }, []);

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleCardClick = (id) => {
    navigate(`/projetos/${id}`);
  };

  const handleVerMaisClick = () => {
    navigate('/projetos');
  };
  
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 5000, 
    afterChange: (index) => {
      document.querySelectorAll('.custom-dots button').forEach((dot, i) => {
        dot.classList.toggle('active', i === index);
      });
    }
  }; 

  const calcularPorcentagem = (arrecadado, meta) => (arrecadado / meta) * 100;
  const corProgresso = (porcentagem) => {
    if (porcentagem < 25) return '#dc3545';
    if (porcentagem < 50) return '#ffc107';
    return '#28a745';
  };
  const isMobile = windowWidth < 768;

  if (carregando) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <BeatLoader color="#001326" />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <div className="carousel-container" style={{ position: 'relative' }}>
        <button className="custom-arrow left-arrow" onClick={() => sliderRef.current.slickPrev()} style={{ position: 'absolute', top: '50%', left: '32px', zIndex: '100' }}>‹</button>
        <Slider ref={sliderRef} {...settings}>
          <div className="carousel-slide"><img src={isMobile ? "/img/banner1_mobile.png" : "/img/banner1.png"} alt="Slide 1" /></div>
          <div className="carousel-slide"><img src={isMobile ? "/img/banner2_mobile.png" : "/img/banner2.png"} alt="Slide 2" /></div>
          <div className="carousel-slide"><img src={isMobile ? "/img/banner3_mobile.png" : "/img/banner3.png"} alt="Slide 3" /></div>
        </Slider>
        <button className="custom-arrow right-arrow" onClick={() => sliderRef.current.slickNext()} style={{ position: 'absolute', top: '50%', right: '32px', zIndex: '100' }}>›</button>
        <div className="custom-dots" style={{ textAlign: 'center', position: 'absolute', bottom: '10px', width: '100%' }}>
          <button className="active" onClick={() => sliderRef.current.slickGoTo(0)}></button>
          <button onClick={() => sliderRef.current.slickGoTo(1)}></button>
          <button onClick={() => sliderRef.current.slickGoTo(2)}></button>
        </div>
      </div>
      <div style={{ backgroundColor: '#f5f5f5', padding: '20px', color: '#ffc85c', textAlign: 'center' }}>
  <h2 style={{ color: '#001326' }}>PROJETOS</h2>
  <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>
    {projetos.map(projeto => (
      <div 
        key={projeto.tb03_id_projeto}
        onClick={() => handleCardClick(projeto.tb03_id_projeto)}
        className="projeto-home"
        style={{ 
          cursor: 'pointer',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          margin: '10px',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          ':hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
          }
        }}
      >
        <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} style={{ width: '300px', height: '300px', borderRadius: '8px 8px 0 0' }} />
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#001326'}}>{projeto.tb03_nome}</h3>
          <div style={{ width: '100%', backgroundColor: '#f1f1f1', borderRadius: '8px' }}>
            <div style={{ width: `${calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta)}%`, backgroundColor: corProgresso(calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta)), borderRadius: '8px', height: '10px' }}></div>
          </div>
          <span style={{ color: '#001326' }}>{calcularPorcentagem(projeto.tb03_arrecadado, projeto.tb03_meta_bruta).toFixed(2)}% de R$ {projeto.tb03_meta_bruta}</span>
        </div>
      </div>
    ))}
  </div>
  <button onClick={handleVerMaisClick} className="vermais-home" style={{ backgroundColor: '#ffffff', color: '#001326', padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', margin: '20px auto', display: 'block' }}>
    Ver mais projetos
  </button>
</div>
<About /> 
    </div>
   
  );
};

export default CarouselComponent;